.loader {
  margin: 16px auto;
  border: 16px solid #fff; /* Light grey */
  border-top: 16px solid #005781; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tf-sponsor-label {
  font-weight: 600;
}

.section-2 {
  border-bottom: none;
}

.blend-filter {
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(200, 206, 210, 0.2), rgba(200, 206, 210, 0.2));  
}

.text-left {
  text-align: left !important;
}

.tf-sponsor-link {
  margin-bottom: 0;
}

.tf-sponsor-box {
  text-align: center;  
  padding-top: 10px;
  padding-bottom: 10px;
}
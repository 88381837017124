.loader {
  border: 16px solid #fff;
  border-top-color: #005781;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 16px auto;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tf-sponsor-label {
  font-weight: 600;
}

.section-2 {
  border-bottom: none;
}

.blend-filter {
  background-blend-mode: multiply;
  background-image: linear-gradient(#c8ced233, #c8ced233);
}

.text-left {
  text-align: left !important;
}

.tf-sponsor-link {
  margin-bottom: 0;
}

.tf-sponsor-box {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
/*# sourceMappingURL=index.css.map */
